import { Injectable } from "@angular/core";
import { CropsPO, IngredientsData, RecipeInfoPO, STiRResultListPO, STiRResultPO, TreatmentPO, UserClaim } from "../models";
import { MsalService } from "@azure/msal-angular";
import { AppConstants } from "../shared/constants";
import { BaseService } from "./base.service";
import { Observable, map } from "rxjs";
import { StationsPO, IAllTypesPO, IGridPreference } from "../models";

@Injectable({
    providedIn: "root",
})
export class SharedService {

    claims: UserClaim = new UserClaim();
    roles: string[] = [];
    constructor(private msalService: MsalService, private baseService: BaseService) {
        let allAccounts = this.msalService.instance.getAllAccounts()
        if (allAccounts.length > 0) {
            let account = allAccounts[0];
            this.roles = account.idTokenClaims!.roles;
        }

    }

    setSessionStorageValue(claims: UserClaim) {
        this.claims = claims
    }

    getSessionStorageValue(): any {
        return this.claims;
    }

    clearGridFilters(gridApi: any) {
        gridApi.setFilterModel(null);
    }

    hasAdmin(): boolean {
        return this.roles.includes(AppConstants.STiR_Admin_Role);
    }

    hasUser(): boolean {
        return this.roles.includes(AppConstants.STiR_User_Role);
    }


    fetchCountries(): Observable<StationsPO[]> {
        let url = "Common/GetCountries";
        return this.baseService.Get(url, AppConstants.Loading);
    }

    async getAllTypes(types: string[]): Promise<IAllTypesPO[]> {
        let url = 'AllType/GetTypesDetails';
        const res = await this.baseService.awaitPost(url, types, AppConstants.Loading);
        return res;
    }

    fetchCrops(): Observable<CropsPO[]> {
        let url = "Common/GetCrops";
        return this.baseService.Get(url, AppConstants.Loading);
    }

    fetchTreatmentsNotUsedInRecipes(): Observable<TreatmentPO[]> {
        let url = "Common/FetchTreatmentsNotUsedInRecipes";
        return this.baseService.Get(url, AppConstants.Loading);
    }

    getGridPreferences(data: IGridPreference): Observable<any> {
        let url = "Common/GetGridPreferences";
        return this.baseService.Post(url, data, AppConstants.Loading);
    }

    saveGridPreferences(data: IGridPreference): Observable<any> {
        let url = 'Common/SaveGridPreferences'
        return this.baseService.Post(url, data, AppConstants.Saving);
    }

    getIngredients(): Observable<STiRResultPO<IngredientsData>> {
        let url = 'Common/GetIngredients'
        return this.baseService.Get(url, AppConstants.Loading);
    }

    getAuditHistory(method: string, id: string): any {
        let url = 'Common/' + method;
        return this.baseService.GetByID(url, id);
    }

    getRecipesForOvertreat(cropCodes: string[]): Observable<STiRResultListPO<RecipeInfoPO>> {
        let url = 'Common/GetRecipesForOvertreat'
        return this.baseService.Post(url, cropCodes, AppConstants.Loading);
    }
}